import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-network-error-handel',
  templateUrl: './network-error-handel.component.html',
  styleUrls: ['./network-error-handel.component.css'],
  providers: [MessageService]
})
export class NetworkErrorHandelComponent implements OnInit, OnDestroy {

  isOffline: boolean = false;
  private onlineHandler: () => void;
  private offlineHandler: () => void;
  private loadingSubscription: Subscription;
  offlineImg: any;

  constructor(private loadingService: LoadingService, 
    private _ngxSpinnerService: NgxSpinnerService,
    private messageService: MessageService,
    private _location: Location,
    private _router: Router
  ) {
    this.onlineHandler = this.checkNetworkStatus.bind(this);
    this.offlineHandler = this.checkNetworkStatus.bind(this);
    this.offlineImg = JSON.parse(localStorage.getItem('offlineImg'))    
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
    window.addEventListener('online', this.onlineHandler);
    window.addEventListener('offline', this.offlineHandler);

    this.loadingSubscription = this.loadingService.loadingSub.subscribe(isLoading => {
      if (isLoading) {
        console.log('Loading...');
        this.loadingService.loadingSub.next(false);
      } else {
        console.log('Loading finished.');
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('online', this.onlineHandler);
    window.removeEventListener('offline', this.offlineHandler);

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  checkNetworkStatus(): void {
    this.isOffline = !navigator.onLine;
  }

  // handleRefresh(): void {
  //   if (navigator.onLine) {
  //     // window.location.href = `${window.location.href.split('?')[0]}?t=${new Date().getTime()}`;
  //     // this._location.back();
  //     this._router.navigate(['/features/hoddashboard']);
  //   } else {
  //     this.messageService.add({
  //       severity: 'error',
  //       summary: 'Network Error',
  //       detail: 'No internet connection. Please check your network settings and try again.'
  //     });   
  //   }
  // }
}
