export class AlphaColor {
  public static colors: any[] = [
    {
      character: "A",
      color: "#641E16",
    },
    {
      character: "B",
      color: "#641E16",
    },
    {
      character: "C",
      color: "#512E5F ",
    },
    {
      character: "D",
      color: "#4A235A",
    },
    {
      character: "E",
      color: "#154360 ",
    },
    {
      character: "F",
      color: "#1B4F72 ",
    },
    {
      character: "G",
      color: "#0E6251",
    },
    {
      character: "H",
      color: "#0B5345",
    },
    {
      character: "I",
      color: "##145A32 ",
    },
    {
      character: "J",
      color: "#186A3B ",
    },
    {
      character: "K",
      color: "#7D6608 ",
    },
    {
      character: "L",
      color: "#7E5109",
    },
    {
      character: "M",
      color: "#B669AD",
    },
    {
      character: "N",
      color: "#784212",
    },
    {
      character: "O",
      color: "#6E2C00",
    },
    {
      character: "P",
      color: "#7B7D7D",
    },
    {
      character: "Q",
      color: "#626567",
    },
    {
      character: "R",
      color: "#4D5656 ",
    },
    {
      character: "S",
      color: "#424949",
    },
    {
      character: "T",
      color: "#1B2631",
    },
    {
      character: "U",
      color: "#17202A",
    },
    {
      character: "V",
      color: "#7B241C",
    },
    {
      character: "W",
      color: "#212F3D",
    },
    {
      character: "X",
      color: "#196F3D",
    },
    {
      character: "Y",
      color: "#BA4A00 ",
    },
    {
      character: "Z",
      color: "#000000",
    },
  ];
}
