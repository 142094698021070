import { EventEmitter, Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  @Input() pageData: any;
  @Output() buttonClickEvent = new EventEmitter<any>();
  @Output() cOffButtonEvent = new EventEmitter<any>();
  constructor() {
  }

  ngOnInit(): void {
    //debugger
    //console.log(this.pageData, "@pagedata")
  }

  buttonClick() {
    this.buttonClickEvent.emit();
  }
  buttonClickCoff() {
    this.cOffButtonEvent.emit();
  }
}
