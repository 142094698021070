import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RestApiService } from 'src/app/core/services/rest-api.service';
@Component({
  selector: 'app-header-view-attachment',
  templateUrl: './header-view-attachment.component.html',
  styleUrls: ['./header-view-attachment.component.css']
})
export class HeaderViewAttachmentComponent implements OnInit {
  EventDetails: any;
  ListOfAttechment: any;
  AttechmentModal: boolean;
  AttechmentUser: any;
  PdfAttechment: any;
  zoom: number =1.0;

  constructor(
    private _ref: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _restApiService: RestApiService, 
  ) {
    this.EventDetails = this._dialogConfig.data;
    console.log("Attach",this.EventDetails);
    
   }

  ngOnInit(): void {
    if(this.EventDetails.EventAttInd==1){
      this.GetAlertAttachmentEvent();
    }else{
      this.GetAlertAttachment(true);
    }
   
  }


  zoomIn(){
    this.zoom += 0.5;
  }
  zoomOut(){
    this.zoom -= 0.5;
  }
  
  GetAlertAttachment(isTrue) {
    //debugger
    // this.AttechmentModal = isTrue;
    var resource = "HRAnnouncements/GetAlertAttachment";
    var Params = {
      DocNo:this.EventDetails.DocNo + ""
    }

    this._restApiService.PostMethod(resource, Params).subscribe((result) => {
      if (result.statusCode == '1') {
        var Data = JSON.parse(result.data);
        console.log(Data);
        
        this.ListOfAttechment = Data[0];
        debugger
        if (this.ListOfAttechment.Attachment_Ext != '.pdf') {
          let ext=this.ListOfAttechment.Attachment_Ext.substring(1);
          console.log(ext);
          
          var newdata = this.ListOfAttechment.Attachment_Data.replace(`data:image/${ext};base64,`, '').replace('data:image/png;base64,', '')
  
          this.AttechmentUser = newdata;
          // console.log("Attachment",this.AttechmentUser);
          
        } else {
          if(!(this.ListOfAttechment.Attachment_Data.startsWith('data:application/pdf;base64,'))){
            this.PdfAttechment='data:application/pdf;base64,'+this.ListOfAttechment.Attachment_Data;
          }else{
            this.PdfAttechment = this.ListOfAttechment.Attachment_Data;
          }
          // var DataPdf = this.ListOfAttechment.Attachment_Data
          
        //  console.log(this.PdfAttechment);
         
        }

      }
    })
  }
  GetAlertAttachmentEvent(){
    var resource = "EventsAndNews/GetEventAttachment";
    var Params = {
      EventId:this.EventDetails.DocNo + ""
    }
    this._restApiService.PostMethod(resource, Params).subscribe((result) => {
      if (result.statusCode == '1') {
        var Data = JSON.parse(result.data);
        console.log(Data);  
        debugger  
        this.ListOfAttechment = Data[0];
        if (this.ListOfAttechment.Attachment_Ext != '.pdf') {
          let ext=this.ListOfAttechment.Attachment_Ext.substring(1);
          // console.log(ext);
          
          var newdata = this.ListOfAttechment.AttachmentData.replace(`data:image/${ext};base64,`, '').replace('data:image/png;base64,', '')
  
          this.AttechmentUser = newdata;
          // console.log("Attachment",this.AttechmentUser);
          
        } else {
          if(!(this.ListOfAttechment.AttachmentData.startsWith('data:application/pdf;base64,'))){
            this.PdfAttechment='data:application/pdf;base64,'+this.ListOfAttechment.AttachmentData;
          }else{
            this.PdfAttechment = this.ListOfAttechment.AttachmentData;
          }
          // var DataPdf = this.ListOfAttechment.Attachment_Data
          
        //  console.log(this.PdfAttechment);
         
        }

      }
    })
  }
}
