import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor(private readonly http: HttpClient) { }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
  public async showAddress(lat: any, lng: any) {
    return this.http.get<any>(
      `https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}`
    );
  }
}
