import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router  } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineGuard implements CanActivate {

  constructor(private router: Router, private configService: ConfigurationService) {}

  canActivate(): boolean {
    if (!this.configService.isOnline) {
      this.router.navigate(['/networkerrorpage']);
      return false;
    }
    return true;
  }
  
}
