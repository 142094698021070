import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrameBustingService {

  constructor() { }
  preventClickjacking(): void {
    // debugger
    if (window.top !== window.self) {
      try {
        window.top.location.href = window.self.location.href;
      } catch (e) {
        console.error('Frame busting failed: ', e);
        document.body.innerHTML = '<h1>Frame Busting Failed: Clickjacking Protection Active</h1>';
      }
    }
    // if (self !== top) {
    //   console.log( top.location.href );
    //   console.log(self.location.href);
    //   top.location.href = self.location.href;
    // }
    // if (window.self !== window.top) {
    //   if (window.top.location.hostname === window.location.hostname) {
    //     window.top.location.href = window.location.href;
    //   } else {
    //     console.warn('Cross-origin framing detected. Unable to navigate top-level window.');
    //   }
    // }
  }
}
