// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyASOBhCvi5Kh2j-C8UsU-EJfW4EofKUiZ8",
    authDomain: "webess-c986d.firebaseapp.com",
    projectId: "webess-c986d",
    storageBucket: "webess-c986d.appspot.com",
    messagingSenderId: "378570980687",
    appId: "1:378570980687:web:358ca077755197e7ebd93a",
    measurementId: "G-G4QBJR2H8C",
    vapidKey:"BKOn6XDhEAhBnTjWFwmHNB7Hp-N3LHarN5nF9w6Hy7YquissvTbg07h4dUv3kqmGGi5EE0aKMMJmMsfBJfnrqfU"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
