import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { ConfigurationService } from './shared/services/configuration.service';
import { RestApiService } from './core/services/rest-api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHeaderInterceptor } from './core/interceptor/auth-header.interceptor';
import { LoadingService } from './core/services/loading.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSpinnerModule } from "ngx-spinner";
import { LockValidationHelperService } from './core/services/lock-validation-helper.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AttachmentViewerComponent } from './shared/component/attachment-viewer/attachment-viewer.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { UrlService } from './core/services/url.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InrCurrencyPipe } from './core/pipes/inr-currency.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HederNotificationsComponent } from './header/heder-notifications/heder-notifications.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimePipePipe } from './core/pipes/time-pipe/time-pipe.pipe';
import { ApprovedApplicationTabComponent } from './features/application-approval/editcomponents/approved-application-tab/approved-application-tab.component';
import { HeaderEventModelComponent } from './header/header-event-model/header-event-model.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderEventdetailsComponent } from './header/header-eventdetails/header-eventdetails.component';
import { FooterComponent } from './footer/footer.component';

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { HeaderViewAttachmentComponent } from './header/header-view-attachment/header-view-attachment.component';
import { NetworkErrorHandelComponent } from './shared/component/network-error-handel/network-error-handel.component';
initializeApp(environment.firebase);
export function configFactory(configurationService: ConfigurationService): any {
  const promise = configurationService.loadConfig().then((value) => {
  });
  return () => promise;
}

@NgModule({
  declarations: [
    AppComponent,
    AttachmentViewerComponent,
    InrCurrencyPipe,
    PageNotFoundComponent,
    //HederNotificationsComponent,
    TimePipePipe,
    ApprovedApplicationTabComponent,
    HeaderEventModelComponent,
    FooterComponent,
    HeaderEventdetailsComponent,
    HeaderViewAttachmentComponent,
    NetworkErrorHandelComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    FullCalendarModule,
    NgbModule,
    ToastrModule.forRoot(
      {
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
        closeButton: true
      }
    ),
    HttpClientModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    DialogModule,
    ConfirmDialogModule,
    ButtonModule,
    PdfViewerModule,
    ToastModule,
  ],
  exports: [
    PageNotFoundComponent,
    FooterComponent
  ],
  providers: [
    ConfigurationService,
    RestApiService,
    LockValidationHelperService,
    LoadingService,
    MessageService,
    DialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true
    },
    UrlService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
