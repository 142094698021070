import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Event, Router } from "@angular/router";
import { LoadingService } from "./core/services/loading.service";
import { delay } from "rxjs/operators";
import { UrlService } from "./core/services/url.service";
import { onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
declare const $: any;
import { LockValidationHelperService } from "./core/services/lock-validation-helper.service";
import { ToastrService } from 'ngx-toastr';
import { FrameBustingService } from "./core/services/frame-busting.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {

  title = "smarthr";
  message: any = null;
  url: string;
  loading: boolean = false;
  previousUrl: string = null;
  currentUrl: string = null;
  ngZone: any;
  payloadUrl: any;
  timeout;
  subMenu: any = [];
  userData: any;
  clientCode: any;
  Menu: any = [];
  menuData: any = [];
  mainMenu: any = [];
  submenu: any = [];
  urlComplete = {
    mainUrl: "",
    subUrl: "",
    childUrl: "",
  };
  MenuCounter: any = [];
  ApprovalCounter: any = [];
  sidebarMenus = {
    default: true,
    chat: false,
    settings: false,
  };
  members = {};
  groups = {};
  ResetInd: string;

  constructor(
    public router: Router,
    private _loading: LoadingService,
    private urlService: UrlService,
    private lockValidationHelperService: LockValidationHelperService,
    private _toastr: ToastrService,
    private frameBustingService: FrameBustingService
  ) {
    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.userData = JSON.parse(localStorage.getItem("LoginData"))?.Login[0];
        this.clientCode = localStorage.getItem("ClientCode");
        this.Menu = JSON.parse(localStorage.getItem("EssMenu"))?.mainMenus;
        if (this.Menu?.length > 0) {
          for (let i = 0; i < this.Menu.length; i++) {
            if (this.Menu[i]?.SubMenus.length != 0) {
              this.subMenu.push(...this.Menu[i]?.SubMenus)
            }
          }
          this.Menu.push(...this.subMenu);
          var data = this.Menu.filter(i => i.RedirectUrl == this.router.url);
          if (data.length > 0) {
            await this.handleNavigation(
              data[0]?.RedirectUrl,
              data[0]?.FormName,
              data[0]?.TranCode,
              data[0]
            )
          }
        }
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
        const url = event.url.split("/");
        this.url = url[1];
        if (
          event.url == "/" ||
          event.url == "/login" ||
          event.url == "/login/register" ||
          event.url == "/login/forgot" ||
          event.url == "/login/otp" ||
          event.url == "/login/lockscreen"
        ) {
          document.querySelector("body").classList.add("account-page");
        } else {
          document.querySelector("body").classList.remove("account-page");
        }
        if (event.url == "/error/error404" || event.url == "/error/error500") {
          document.querySelector("body").classList.add("error-page");
        } else {
          document.querySelector("body").classList.remove("error-page");
        }
      }
    });
    this.ResetInd = localStorage.getItem('PasswordResetInd');
  }

  async ngOnInit(): Promise<void> {
    this.listenToLoading();
    // Minified Sidebar
    this.frameBustingService.preventClickjacking();
    $(document).on("click", "#toggle_btn", () => {
      if ($("body").hasClass("mini-sidebar")) {
        $("body").removeClass("mini-sidebar");
        $(".subdrop + ul").slideDown();
      } else {
        $("body").addClass("mini-sidebar");
        $(".subdrop + ul").slideUp();
      }
      return false;
    });

    $(document).on("mouseover", (e) => {
      e.stopPropagation();
      if (
        $("body").hasClass("mini-sidebar") &&
        $("#toggle_btn").is(":visible")
      ) {
        const targ = $(e.target).closest(".sidebar").length;
        if (targ) {
          $("body").addClass("expand-menu");
          $(".subdrop + ul").slideDown();
        } else {
          $("body").removeClass("expand-menu");
          $(".subdrop + ul").slideUp();
        }
        return false;
      }
    });

    $("body").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function () {
      var $wrapper = $(".main-wrapper");
      $wrapper.toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      $("#task_window").removeClass("opened");
      return false;
    });

    $(".sidebar-overlay").on("click", function () {
      var $wrapper = $(".main-wrapper");
      $("html").removeClass("menu-opened");
      $(this).removeClass("opened");
      $wrapper.removeClass("slide-nav");
      $(".sidebar-overlay").removeClass("opened");
      $("#task_window").removeClass("opened");
    });
    this.listen();
    this.refresh();
  }

  refresh() {
    var time = new Date().getTime();
    $(document.body).bind("mousemove keypress", function () {
      time = new Date().getTime();
    });

    function refresh() {
      if (new Date().getTime() - time >= 1200000)
        window.location.reload();
      else
        setTimeout(refresh, 1000);
    }
    setTimeout(refresh, 1000);
  }

  async listenToLoading() {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  async listen(): Promise<void> {
    const messaging = getMessaging();
    onMessage(messaging, async (payload: any) => {
      if (document.hidden) {
        return false;
      }
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
      };
      this.payloadUrl = payload.data.click_action;
      if (!("Notification" in window)) {
        // console.log("This browser does not support system notifications.");
      } else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(notificationTitle, notificationOptions);
        notification.onclick = () => {
          event.preventDefault();
          const url = this.payloadUrl.split('/#');
          this.router.navigate([url[1]]);
          notification.close();
          // window.open(payload.fcmOptions.link, '_blank');
        };
      }
    });
    // const backgroundMessaging = BackgroundMessaging();
    // await onBackgroundMessage(backgroundMessaging, async (payload) => {
    //   const notificationTitle = payload.notification.title;
    //   const notificationOptions = {
    //     body: payload.notification.body,
    //     icon: payload.notification.icon,
    //   };
    //   this.payloadUrl=payload.fcmOptions.link;
    //   console.log(payload);
    //   if (!("Notification" in window)) {
    //     console.log("This browser does not support system notifications.");
    //   } else if (Notification.permission === "granted") {
    //     // If it's okay let's create a notification
    //     var notification = new Notification(notificationTitle, notificationOptions);
    //     notification.onclick =()=> {
    //       event.preventDefault();
    //       const url=this.payloadUrl.split('/#');
    //      // this.router.navigate([url[1]]);
    //       notification.close();
    //       window.open(payload.fcmOptions.link, '_blank');
    //     };
    //   }
    // });
  }

  async handleNavigation(path: string, formName: string, TranCode: string, item: any) {
   
    let url = this.router.url;
    if(url.includes("change-password") && this.ResetInd=='1'){
      this.router.navigate(["features/change-password"]);
    }
    let MustChgPwdAtNextLogin=JSON.parse(localStorage.getItem('MainLoginData')).Login[0].MustChgPwdAtNextLogin;

    if(MustChgPwdAtNextLogin==1){
      this.router.navigate(["features/change-password"]);
    }
    if (formName == "" || formName == undefined || formName == null) {
      return
    }

    let logged = JSON.parse(localStorage.getItem('switchedLoginToSubordinate'));

    if (logged == '0' && (formName == 'frmPollSummaryReport' || formName == 'frmPollDetailReport' || formName=='frmESSOptionalHolidayLimitation')) {
      this._toastr.info(
        "You don't have access to this module!"
      );
      this.router.navigate(["/features/dashboard"]);
    }
    if(this.userData.Recruiter!=1){
      if(formName=='frmCandidateShortListing' || formName=='frmInterviewSchedule' || formName=='frmShortCloseJobRequest' ||
         formName=='frmCandidateFinalization' || formName=='frmOnBoarding' || formName=='frmCandidateEntry' || formName=='frmRecruitmentDashboard'
      ){
        this._toastr.warning("Only A Recruiter Can Access This Module !",
          "Warning!");
        this.router.navigate(["/features/dashboard"]);
        return;
      }
    }
    if (TranCode != '' && TranCode != undefined && TranCode != null) {
      let isEssLicense = this.lockValidationHelperService.ValidateEssLock(TranCode, this.clientCode);
      await isEssLicense.subscribe(async res => {
        if (res.statusCode == "1") {
          var isLocked = await this.lockValidationHelperService.ValidateLock(
            formName,
            this.userData.Company_Code,
            this.clientCode,
            this.userData.Emp_Code,
            this.userData.Emp_Name
          );
          if (isLocked.message.data == "0") {
            if (
              // formName == "frmESSFeedback" ||
              // formName == "frmESSPoll" ||
              // formName == "frmESSMood"||
              // formName == "frmESSITDeclaration" ||
              formName == "frmLeaveApp" ||
              formName == "frmMisPunchApp" ||
              formName == "frmOutStationApp" ||
              formName == "frmShiftChangeApp" ||
              formName == "frmLoanApp" ||
              formName == "frmESSTimeSheetEntry" ||
              formName == "frmEssExpenseList" ||
              formName == "EPNC" ||
              formName == "frmExitRequest" ||
              formName == "frmWorkFromHome" ||
              formName == "frmESSTravelApp" ||
              formName == "frmTravelApplication" ||
              formName == "frmVacancy" ||
              formName == "frmNewVisistRequest" ||
              formName == "frmEpunchWeb" ||
              formName == "frmAdvanceRequestApplication"||
              formName == "frmEssOTBudge" 
            ) {
              var isApproverAvailable =
                await this.lockValidationHelperService.ValidateApproval(
                  this.clientCode,
                  "",
                  this.userData.Emp_Code,
                  this.userData.Emp_Name,
                  formName == "frmEpunchWeb"?'EPNC':formName
                );
              if (isApproverAvailable.data == "1") {
                //return
                if (
                  formName == "frmLeaveApp" ||
                  formName == "frmMisPunchApp" ||
                  formName == "frmOutStationApp" ||
                  formName == "frmWorkFromHome" ||
                  formName == "frmShiftChangeApp" ||
                  formName == "frmEssExpenseList" ||
                  formName == "frmLoanApp" ||
                  formName == "frmExitRequest" ||
                  formName == "frmESSTimeSheetEntry" ||
                  formName == "EPNC" ||
                  formName == "frmESSTravelApp" ||
                  formName == "frmNewVisistRequest" ||
                  formName == "frmEpunchWeb" ||
                  formName == "frmTravelApplication" ||
                  formName == "frmVacancy" ||
                  // formName == "frmESSITDeclaration" ||
                  // formName == "frmESSFeedback" ||
                  // formName == "frmESSPoll" ||
                  // formName == "frmESSMood" ||
                  formName == "frmAdvanceRequestApplication" ||
                  formName == "frmEssOTBudge" || 
                  formName == "frmGetpassApplication" ||
                  formName == "frmESSUnholdSalary" ||
                  formName == "frmExitReqCheckListApproval" ||
                  formName == "frmESSShiftSchdule" ||
                  formName == "frmESSOptionalHolidayLimitation" ||
                  formName == "frmEventsAndNews" ||
                  formName == "frmAlertScreen" ||
                  formName == "frmPolicyAndDocument" ||
                  formName == "frmRenewalSubmission" ||
                  formName == "frmGrievance" ||
                  formName == "frmVisitorGatePass" ||
                  formName == "frmVisitorConfirmation" ||
                  formName == "frmESSTaskAssignment" ||
                  formName == "frmESSTimeSheet" ||
                  formName == "frmESSHoliday" ||
                  formName == "RptEmpSalAccMaster" ||
                  formName == "frmSalarySlip" ||
                  formName == "frmDirectory"
                ) {
                  var Data =
                    await this.lockValidationHelperService.ValidateUserRights(
                      TranCode,
                      this.userData.User_Id + "", item.UserRightsMode
                    );
                  var UserRights = JSON.parse(Data.data)[0]
                  if (UserRights.Rights_Exist == 1) {
                    let loggedInAs = JSON.parse(localStorage.getItem('switchedLoginToSubordinate'));
                    if (TranCode != '' && loggedInAs == 0) {
                      await this.lockValidationHelperService.ValidateHodTranLock(formName, this.clientCode).
                        subscribe(async (res: any) => {
                          if (res.message.data == '1' || res.message.data == '') {
                            this._toastr.warning("HOD Trans Locked",
                              "Warning!");
                            this.router.navigate(["/features/dashboard"]);
                          }
                          else {
                            if (
                              formName == "frmLeaveApp" ||
                              formName == "frmMisPunchApp" ||
                              formName == "frmOutStationApp" ||
                              formName == "frmShiftChangeApp" ||
                              // formName == 'frmEssExpenseList' ||
                              formName == "frmLoanApp" ||
                              formName == "frmESSTimeSheetEntry" ||
                              formName == "frmEssExpenseList" ||
                              formName == "EPNC" ||
                              formName == "frmExitRequest" ||
                              formName == "frmWorkFromHome" ||
                              formName == "frmESSTravelApp" ||
                              formName == "frmNewVisistRequest" ||
                              formName == "frmEssOTBudge"
                            ) {
                              var isApproverAvailable =
                                await this.lockValidationHelperService.ValidateApproval(
                                  this.clientCode,
                                  "",
                                  this.userData.Emp_Code,
                                  this.userData.Emp_Name,
                                  formName
                                );
                              if (isApproverAvailable.data == "1") {
                                if (
                                  formName == "frmLeaveApp" ||
                                  formName == "frmMisPunchApp" ||
                                  formName == "frmOutStationApp" ||
                                  formName == "frmWorkFromHome" ||
                                  formName == "frmShiftChangeApp" ||
                                  formName == "frmEssExpenseList" ||
                                  formName == "frmLoanApp" ||
                                  formName == "frmExitRequest" ||
                                  formName == "frmESSTimeSheetEntry" ||
                                  formName == "EPNC" ||
                                  formName == "frmESSTravelApp" ||
                                  formName == "frmNewVisistRequest" ||
                                  formName == "frmEssOTBudge" ||
                                  formName == "frmRenewalSubmission" ||
                                  formName == "frmTravelApplication" ||
                                  formName == "frmESSUnholdSalary" ||
                                  formName == "frmExitReqCheckListApproval" ||
                                  formName == "frmESSITDeclaration" ||
                                  formName == "frmESSShiftSchdule" ||
                                  formName == "frmESSOptionalHolidayLimitation" ||
                                  formName == "frmEventsAndNews" ||
                                  formName == "frmAlertScreen" ||
                                  formName == "frmPolicyAndDocument" ||
                                  formName == "frmRenewalSubmission" ||
                                  formName == "frmGrievance" ||
                                  formName == "frmVacancy" ||
                                  formName == "frmNewVisistRequest" ||
                                  formName == "frmVisitorGatePass" ||
                                  formName == "frmVisitorConfirmation" ||
                                  formName == "EPNC" ||
                                  formName == "frmESSTaskAssignment" ||
                                  formName == "frmESSFeedback" ||
                                  formName == "frmESSPoll" ||
                                  formName == "frmESSMood" ||
                                  formName == "frmESSTimeSheet" ||
                                  formName == "frmESSHoliday" ||
                                  formName == "RptEmpSalAccMaster" ||
                                  formName == "frmSalarySlip" ||
                                  formName == "frmPolicyAndDocument" ||
                                  formName == "frmDirectory" || 
                                  formName == "frmGetpassApplication" || 
                                  formName == "frmEmpGatePass") {
                                  var Data =
                                    await this.lockValidationHelperService.ValidateUserRights(
                                      TranCode,
                                      this.userData.User_Id + "", item.UserRightsMode
                                    );
                                  var UserRights = JSON.parse(Data.data)[0];
                                  if (UserRights.Rights_Exist == 1) {
                                    return;
                                  }
                                  else if (UserRights.Rights_Exist == 0) {
                                    this._toastr.warning(" User  has no right to access this " + item.Text + "  module",
                                      "Warning!");
                                    this.router.navigate(["/features/dashboard"]);
                                  }
                                }
                                else {
                                  return;
                                }
                              }
                              else if (isApproverAvailable.data == "0") {
                                this._toastr.warning(
                                  "Approver is not available for this user.",
                                  "Warning!"
                                );
                                this.router.navigate(["/features/dashboard"]);
                              }
                            }
                            else {
                              return;
                            }
                          }
                        })
                    }
                    else if (
                      formName == "frmLeaveApp" ||
                      formName == "frmMisPunchApp" ||
                      formName == "frmOutStationApp" ||
                      formName == "frmShiftChangeApp" ||
                      // formName == 'frmEssExpenseList' ||
                      formName == "frmLoanApp" ||
                      formName == "frmESSTimeSheetEntry" ||
                      formName == "frmEssExpenseList" ||
                      formName == "EPNC" ||
                      formName == "frmExitRequest" ||
                      formName == "frmWorkFromHome" ||
                      formName == "frmESSTravelApp" ||
                      formName == "frmNewVisistRequest"
                    ) {
                      var isApproverAvailable =
                        await this.lockValidationHelperService.ValidateApproval(
                          this.clientCode,
                          "",
                          this.userData.Emp_Code,
                          this.userData.Emp_Name,
                          formName
                        );
                      if (isApproverAvailable.data == "1") {
                        if (
                          formName == "frmLeaveApp" ||
                          formName == "frmMisPunchApp" ||
                          formName == "frmOutStationApp" ||
                          formName == "frmWorkFromHome" ||
                          formName == "frmShiftChangeApp" ||
                          formName == "frmEssExpenseList" ||
                          formName == "frmLoanApp" ||
                          formName == "frmExitRequest" ||
                          formName == "frmESSTimeSheetEntry" ||
                          formName == "EPNC" ||
                          formName == "frmESSTravelApp" ||
                          formName == "frmNewVisistRequest" ||
                          formName == "frmRenewalSubmission" ||
                          formName == "frmTravelApplication" ||
                          formName == "frmESSUnholdSalary" ||
                          formName == "frmExitReqCheckListApproval" ||
                          formName == "frmESSITDeclaration" ||
                          formName == "frmESSShiftSchdule" ||
                          formName == "frmESSOptionalHolidayLimitation" ||
                          formName == "frmEventsAndNews" ||
                          formName == "frmAlertScreen" ||
                          formName == "frmPolicyAndDocument" ||
                          formName == "frmRenewalSubmission" ||
                          formName == "frmGrievance" ||
                          formName == "frmVacancy" ||
                          formName == "frmNewVisistRequest" ||
                          formName == "frmVisitorGatePass" ||
                          formName == "frmVisitorConfirmation" ||
                          formName == "EPNC" ||
                          formName == "frmESSTaskAssignment" ||
                          formName == "frmESSFeedback" ||
                          formName == "frmESSPoll" ||
                          formName == "frmESSMood" ||
                          formName == "frmESSTimeSheet" ||
                          formName == "frmESSHoliday" ||
                          formName == "RptEmpSalAccMaster" ||
                          formName == "frmSalarySlip" ||
                          formName == "frmPolicyAndDocument" ||
                          formName == "frmDirectory" || 
                          formName == "frmGetpassApplication" ||
                          formName == "frmEmpGatePass"
                        ) {
                          var Data =
                            await this.lockValidationHelperService.ValidateUserRights(
                              TranCode,
                              this.userData.User_Id + "", item.UserRightsMode
                            );
                          var UserRights = JSON.parse(Data.data)[0];
                          if (UserRights.Rights_Exist == 1) {
                            return
                          }
                          else if (UserRights.Rights_Exist == 0) {
                            this._toastr.warning(
                              " User  has no right to access this " + item.Text + "  module",
                              "Warning!"
                            );
                            this.router.navigate(["/features/dashboard"]);
                          }
                        }
                        else {
                          return
                        }
                      }
                      else if (UserRights.Rights_Exist == 0) {
                        this._toastr.warning(
                          " User  has no right to access this " + item.Text + "  module",
                          "Warning!"
                        );
                        this.router.navigate(["/features/dashboard"]);
                      }
                    }
                  }
                  else {
                    this._toastr.warning(
                      " User  has no right to access this " + item.Text + "  module",
                      "Warning!"
                    );
                    this.router.navigate(["/features/dashboard"]);
                  }
                }
                else if (isApproverAvailable.data == "0") {
                  this._toastr.warning(
                    "Approver is not available for this user.",
                    "Warning!"
                  );
                  this.router.navigate(["/features/dashboard"]);
                }
              }
              else if (isApproverAvailable.data == "0") {
                this._toastr.warning(
                  "Approver is not available for this user.",
                  "Warning!"
                );
                this.router.navigate(["/features/dashboard"]);
              }
              else {
                return
              }
            }
            else {
              if (
                formName == "frmESSFeedback" ||
                formName == "frmESSPoll" ||
                formName == "frmESSMood" ||
                formName == "frmESSITDeclaration" ||
                formName == "frmSalarySlip" ||
                formName == "frmESSTimeSheet" || 
                formName == "frmESSHoliday" ||
                formName == "frmPolicyAndDocument" ||
                formName == "frmDirectory" ||
                formName == "frmESSOptionalHolidayLimitation" ||
                formName == "frmESSShiftSchdule" ||
                formName == "RptEmpSalAccMaster" || 
                formName == "frmESSUnholdSalary" || 
                formName == "frmExitReqCheckListApproval" ||
                formName == "frmGrievance" ||
                formName == "frmVisitorConfirmation" ||
                formName == "frmNewVisistRequest" ||
                formName == "frmESSTaskAssignment" ||
                formName == "frmCandidateEntry" ||
                formName == "frmInterviewSchedule" ||
                formName == "frmInterviewRecord" ||
                formName == "frmCandidateFinalization" ||
                formName == "frmOnBoarding" ||
                formName == "frmCandidateShortListing" ||
                formName == "frmCandidateShortListing" ||
                formName == "frmESSDashBoardHOD"||
                formName == "frmPaywellGreetings" ||
                formName == "frmJobProbationConfirmation" || 
                formName == "frmGetpassApplication" || 
                formName == "frmEmpGatePass"
                ) {
                var Data =
                  await this.lockValidationHelperService.ValidateUserRights(
                    TranCode,
                    this.userData.User_Id + "", item.UserRightsMode
                  );
                var UserRights = JSON.parse(Data.data)[0]
                if (UserRights.Rights_Exist == 1) {
                  let loggedInAs = JSON.parse(localStorage.getItem('switchedLoginToSubordinate'));
                  if (TranCode != '' && loggedInAs == 0) {
                    await this.lockValidationHelperService.ValidateHodTranLock(formName, this.clientCode).
                      subscribe(async (res: any) => {
                        if (res.message.data == '1' || res.message.data == '') {
                          this._toastr.warning("HOD Trans Locked",
                            "Warning!");
                          this.router.navigate(["/features/dashboard"]);
                        } else {
                          return;                                               //pass return statement if this route path passed is correct
                        }
                      })
                  }
                } else {
                  this._toastr.warning("User has no right to access this " + item.Text + " module",
                    "Warning!");
                  this.router.navigate(["/features/dashboard"]);
                }
              }
            }
          }
          else {
            this._toastr.info(
              item.Text + " module is temporarily locked.",
              "Other!"
            );
            this.router.navigate(["/features/dashboard"]);
          }
        }
        else if (res.statusCode == "0") {
          this._toastr.warning(res.message,
            "Warning!");
          this.router.navigate(["/features/dashboard"]);
          return
        }
      })
    } else {
      var isLocked = await this.lockValidationHelperService.ValidateLock(
        formName,
        this.userData.Company_Code,
        this.clientCode,
        this.userData.Emp_Code,
        this.userData.Emp_Name
      );
      if (isLocked.message.data == "0") {
        if (
          formName == "frmLeaveApp" ||
          formName == "frmMisPunchApp" ||
          formName == "frmOutStationApp" ||
          formName == "frmShiftChangeApp" ||
          formName == "frmLoanApp" ||
          formName == "frmESSTimeSheetEntry" ||
          formName == "frmEssExpenseList" ||
          formName == "EPNC" ||
          formName == "frmExitRequest" ||
          formName == "frmWorkFromHome" ||
          formName == "frmESSTravelApp" ||
          formName == "frmTravelApplication" ||
          formName == "frmNewVisistRequest"
        ) {
          var isApproverAvailable =
            await this.lockValidationHelperService.ValidateApproval(
              this.clientCode,
              "",
              this.userData.Emp_Code,
              this.userData.Emp_Name,
              formName
            );

          if (isApproverAvailable.data == "1") {
            //return
            if (
              formName == "frmLeaveApp" ||
              formName == "frmMisPunchApp" ||
              formName == "frmOutStationApp" ||
              formName == "frmWorkFromHome" ||
              formName == "frmShiftChangeApp" ||
              formName == "frmEssExpenseList" ||
              formName == "frmLoanApp" ||
              formName == "frmExitRequest" ||
              formName == "frmESSTimeSheetEntry" ||
              formName == "EPNC" ||
              formName == "frmESSTravelApp" ||
              formName == "frmNewVisistRequest" ||
              formName == "frmTravelApplication" ||
              formName == "frmESSUnholdSalary" ||
              formName == "frmExitReqCheckListApproval" ||
              formName == "frmESSITDeclaration" ||
              formName == "frmESSShiftSchdule" ||
              formName == "frmESSOptionalHolidayLimitation" ||
              formName == "frmEventsAndNews" ||
              formName == "frmAlertScreen" ||
              formName == "frmPolicyAndDocument" ||
              formName == "frmRenewalSubmission" ||
              formName == "frmGrievance" ||
              formName == "frmVacancy" ||
              formName == "frmVisitorGatePass" ||
              formName == "frmVisitorConfirmation" ||
              formName == "frmESSTaskAssignment" ||
              formName == "frmESSFeedback" ||
              formName == "frmESSPoll" ||
              formName == "frmESSMood" ||
              formName == "frmESSTimeSheet" ||
              formName == "frmESSHoliday" ||
              formName == "RptEmpSalAccMaster" ||
              formName == "frmSalarySlip" ||
              formName == "frmDirectory" || 
              formName == "frmGetpassApplication" || 
              formName == "frmEmpGatePass"
            ) {
              var Data =
                await this.lockValidationHelperService.ValidateUserRights(
                  TranCode,
                  this.userData.User_Id + "", item.UserRightsMode
                );
              var UserRights = JSON.parse(Data.data)[0]
              if (UserRights.Rights_Exist == 1) {
                let loggedInAs = JSON.parse(localStorage.getItem('switchedLoginToSubordinate'));
                if (TranCode != '' && loggedInAs == 0) {
                  await this.lockValidationHelperService.ValidateHodTranLock(formName, this.clientCode).
                    subscribe(async (res: any) => {
                      if (res.message.data == '1' || res.message.data == '') {
                        this._toastr.warning("HOD Trans Locked",
                          "Warning!");
                        this.router.navigate(["/features/dashboard"]);
                      }
                      else {
                        if (
                          formName == "frmLeaveApp" ||
                          formName == "frmMisPunchApp" ||
                          formName == "frmOutStationApp" ||
                          formName == "frmShiftChangeApp" ||
                          // formName == 'frmEssExpenseList' ||
                          formName == "frmLoanApp" ||
                          formName == "frmESSTimeSheetEntry" ||
                          formName == "frmEssExpenseList" ||
                          formName == "EPNC" ||
                          formName == "frmExitRequest" ||
                          formName == "frmWorkFromHome" ||
                          formName == "frmESSTravelApp" ||
                          formName == "frmNewVisistRequest"
                        ) {
                          var isApproverAvailable =
                            await this.lockValidationHelperService.ValidateApproval(
                              this.clientCode,
                              "",
                              this.userData.Emp_Code,
                              this.userData.Emp_Name,
                              formName
                            );
                          if (isApproverAvailable.data == "1") {
                            if (
                              formName == "frmLeaveApp" ||
                              formName == "frmMisPunchApp" ||
                              formName == "frmOutStationApp" ||
                              formName == "frmWorkFromHome" ||
                              formName == "frmShiftChangeApp" ||
                              formName == "frmEssExpenseList" ||
                              formName == "frmLoanApp" ||
                              formName == "frmExitRequest" ||
                              formName == "frmESSTimeSheetEntry" ||
                              formName == "EPNC" ||
                              formName == "frmESSTravelApp" ||
                              formName == "frmNewVisistRequest" ||
                              formName == "frmRenewalSubmission" ||
                              formName == "frmTravelApplication" ||
                              formName == "frmESSUnholdSalary" ||
                              formName == "frmExitReqCheckListApproval" ||
                              formName == "frmESSITDeclaration" ||
                              formName == "frmESSShiftSchdule" ||
                              formName == "frmESSOptionalHolidayLimitation" ||
                              formName == "frmEventsAndNews" ||
                              formName == "frmAlertScreen" ||
                              formName == "frmPolicyAndDocument" ||
                              formName == "frmRenewalSubmission" ||
                              formName == "frmGrievance" ||
                              formName == "frmVacancy" ||
                              formName == "frmNewVisistRequest" ||
                              formName == "frmVisitorGatePass" ||
                              formName == "frmVisitorConfirmation" ||
                              formName == "EPNC" ||
                              formName == "frmESSTaskAssignment" ||
                              formName == "frmESSFeedback" ||
                              formName == "frmESSPoll" ||
                              formName == "frmESSMood" ||
                              formName == "frmESSTimeSheet" ||
                              formName == "frmESSHoliday" ||
                              formName == "RptEmpSalAccMaster" ||
                              formName == "frmSalarySlip" ||
                              formName == "frmPolicyAndDocument" ||
                              formName == "frmDirectory" || 
                              formName == "frmGetpassApplication" ||
                              formName == "frmEmpGatePass") {
                              var Data =
                                await this.lockValidationHelperService.ValidateUserRights(
                                  TranCode,
                                  this.userData.User_Id + "", item.UserRightsMode
                                );
                              var UserRights = JSON.parse(Data.data)[0];
                              if (UserRights.Rights_Exist == 1) {
                                return;
                              }
                              else if (UserRights.Rights_Exist == 0) {
                                this._toastr.warning(" User  has no right to access this " + item.Text + "  module",
                                  "Warning!");
                                this.router.navigate(["/features/dashboard"]);
                              }
                            }
                            else {
                              return;
                            }
                          }
                          else if (isApproverAvailable.data == "0") {
                            this._toastr.warning(
                              "Approver is not available for this user.",
                              "Warning!"
                            );
                            this.router.navigate(["/features/dashboard"]);
                          }
                        }
                        else {
                          return;
                        }
                      }
                    })
                }
                else if (
                  formName == "frmLeaveApp" ||
                  formName == "frmMisPunchApp" ||
                  formName == "frmOutStationApp" ||
                  formName == "frmShiftChangeApp" ||
                  // formName == 'frmEssExpenseList' ||
                  formName == "frmLoanApp" ||
                  formName == "frmESSTimeSheetEntry" ||
                  formName == "frmEssExpenseList" ||
                  formName == "EPNC" ||
                  formName == "frmExitRequest" ||
                  formName == "frmWorkFromHome" ||
                  formName == "frmESSTravelApp" ||
                  formName == "frmNewVisistRequest"
                ) {
                  var isApproverAvailable =
                    await this.lockValidationHelperService.ValidateApproval(
                      this.clientCode,
                      "",
                      this.userData.Emp_Code,
                      this.userData.Emp_Name,
                      formName
                    );
                  if (isApproverAvailable.data == "1") {
                    if (
                      formName == "frmLeaveApp" ||
                      formName == "frmMisPunchApp" ||
                      formName == "frmOutStationApp" ||
                      formName == "frmWorkFromHome" ||
                      formName == "frmShiftChangeApp" ||
                      formName == "frmEssExpenseList" ||
                      formName == "frmLoanApp" ||
                      formName == "frmExitRequest" ||
                      formName == "frmESSTimeSheetEntry" ||
                      formName == "EPNC" ||
                      formName == "frmESSTravelApp" ||
                      formName == "frmNewVisistRequest" ||
                      formName == "frmRenewalSubmission" ||
                      formName == "frmTravelApplication" ||
                      formName == "frmESSUnholdSalary" ||
                      formName == "frmExitReqCheckListApproval" ||
                      formName == "frmESSITDeclaration" ||
                      formName == "frmESSShiftSchdule" ||
                      formName == "frmESSOptionalHolidayLimitation" ||
                      formName == "frmEventsAndNews" ||
                      formName == "frmAlertScreen" ||
                      formName == "frmPolicyAndDocument" ||
                      formName == "frmRenewalSubmission" ||
                      formName == "frmGrievance" ||
                      formName == "frmVacancy" ||
                      formName == "frmNewVisistRequest" ||
                      formName == "frmVisitorGatePass" ||
                      formName == "frmVisitorConfirmation" ||
                      formName == "EPNC" ||
                      formName == "frmESSTaskAssignment" ||
                      formName == "frmESSFeedback" ||
                      formName == "frmESSPoll" ||
                      formName == "frmESSMood" ||
                      formName == "frmESSTimeSheet" ||
                      formName == "frmESSHoliday" ||
                      formName == "RptEmpSalAccMaster" ||
                      formName == "frmSalarySlip" ||
                      formName == "frmPolicyAndDocument" ||
                      formName == "frmDirectory" || 
                      formName == "frmGetpassApplication" || 
                      formName == "frmEmpGatePass"
                    ) {
                      var Data =
                        await this.lockValidationHelperService.ValidateUserRights(
                          TranCode,
                          this.userData.User_Id + "", item.UserRightsMode
                        );
                      var UserRights = JSON.parse(Data.data)[0];
                      if (UserRights.Rights_Exist == 1) {
                        return
                      }
                      else if (UserRights.Rights_Exist == 0) {
                        this._toastr.warning(
                          " User has no right to access this " + item.Text + " module",
                          "Warning!"
                        );
                        this.router.navigate(["/features/dashboard"]);
                      }
                    }
                    else {
                      return
                    }
                  }
                  else if (UserRights.Rights_Exist == 0) {
                    this._toastr.warning(
                      " User has no right to access this " + item.Text + " module",
                      "Warning!"
                    );
                    this.router.navigate(["/features/dashboard"]);
                  }
                }
              }
              else {
                this._toastr.warning(
                  " User has no right to access this " + item.Text + " module",
                  "Warning!"
                );
                this.router.navigate(["/features/dashboard"]);
              }
            }
            else if (isApproverAvailable.data == "0") {
              this._toastr.warning(
                "Approver is not available for this user.",
                "Warning!"
              );
              this.router.navigate(["/features/dashboard"]);
            }
          }
          else if (isApproverAvailable.data == "0") {
            this._toastr.warning(
              "Approver is not available for this user.",
              "Warning!"
            );
            this.router.navigate(["/features/dashboard"]);
          }
          else {
            return
          }
        }
        else {
          return
        }
      }

      else {
        this._toastr.info(
          item.Text + " module is temporarily locked.",
          "Other!"
        );
        this.router.navigate(["/features/dashboard"]);
      }
    }
  }
}
