
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LockValidationHelperService } from 'src/app/core/services/lock-validation-helper.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';


@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.css']
})
export class AttachmentViewerComponent implements OnInit {

  userData;
  clientCode;
  attachment;
  attachmentId;
  imageUrl;
  att;
  zoom: number = 1.3;
  constructor(
    public dialogService: DialogService,
    private dialogConfig: DynamicDialogConfig,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private _restApiService: RestApiService
  ) {
    this.userData = JSON.parse(localStorage.getItem('LoginData')).Login[0];
    this.clientCode = localStorage.getItem('ClientCode');
    this.attachmentId = this.dialogConfig.data.attachmentId;
  }

  zoomIn(){
    this.zoom += 0.5;
  }
  zoomOut(){
    this.zoom -= 0.5;
  }
  transform() {
    if (this.imageUrl === null) return '';
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.imageUrl);
  }

  ngOnInit(): void {
    this.getAttachment();
  }

  getAttachment() {
    var resource = 'TaskAssignment/GetTaskAttachment';

    var parameters = {
      "AttachmentId": this.attachmentId + ''
    };

    this
      ._restApiService
      .PostMethod(resource, parameters)
      .subscribe(
        (result) => {
          console.log('Task Attachment', JSON.parse(result.data));
          this.att = JSON.parse(result.data)[0];
          let ext=this.att.Attachment_Ext;
          if (ext!='.pdf') {
            // let ext = details.Attachment_Extention;
            ext = ext.substr(1);
            console.log(ext);
            debugger
            if(this.att.TaskAttachment.startsWith(`data:image/${ext};base64,`)){
              // var newdata = this.att.TaskAttachment.replace(`data:image/${ext};base64,`, '').replace('data:image/png;base64,', '')
              // this.AttechmentUser = newdata;
              // var newdata=this.att.TaskAttachment;
              this.imageUrl=this.att.TaskAttachment;
              // console.log("imgp",this.imageUrl);
            }else{
              var newdata=`data:image/${ext};base64,`+this.att.TaskAttachment;
              this.imageUrl=newdata;
              // console.log("imgp",this.imageUrl);
            }
           
            
          } else {
            if(this.att.TaskAttachment.startsWith('data:application/pdf;base64,')){
              // this.PdfAttechment=this.ListOfAttechment;
              this.attachment = this.att.TaskAttachment;
               this.imageUrl = this.attachment;
              //  console.log("imgpdf",this.imageUrl);
            }else{
              this.imageUrl=`data:application/pdf;base64,${this.att.TaskAttachment}`;
              // console.log("imgpdf",this.imageUrl);
            }
            // var DataPdf = this.ListOfAttechment
            // this.PdfAttechment = DataPdf;
          }
          // if(this.att.Attachment_Ext!='.pdf'){

          // }
          
        }
      );
  }

}
