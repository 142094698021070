import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class LockValidationHelperService {

  constructor(
    private _restApiService: RestApiService,
    private _toastr: ToastrService
  ) {

  }
  ValidateEssLock(
    TranCode: string,
    clientCode: string) {
    let parameters = {
      "TranCode": TranCode + '',
      "ClientCode": clientCode + ''
    }
    return this
      ._restApiService
      .PostMethod('ValidateLock/ValidateTransactionLicence', parameters);
  }
  ValidateLock(
    formName: string,
    companyCode: string,
    clientCode: string,
    gEmpCode: string,
    gEmpName: string
  ) {
    var parameters = {
      "ClientCode": clientCode + '',
      "CompanyCode": companyCode + '',
      "formName": formName + '',
      "gEmpCode": gEmpCode + '',
      "gEmpName": gEmpName + '',
      "str": ''
    }
    return this
      ._restApiService
      .PostMethod('ValidateLock/ValidateLock', parameters)
      .toPromise();
  }
  ValidateHodTranLock(
    formName: string,
    clientCode: string) {
    let parameters = {
      "formName": formName + '',
      "ClientCode": clientCode + ''
    }
    return this
      ._restApiService
      .PostMethod('ValidateLock/ValidateLockForHOD', parameters);
  }
  ValidatePeriodLock(
    formName: string,
    pApplyFrmDate: string,
    pApplyToDate: string,
    clientCode: string
  ) {
    var parameters = {
      "formName": formName + '',
      "pApplyFrmDate": pApplyFrmDate + '',
      "pApplyToDate": pApplyToDate + '',
      "ClientCode": clientCode + ''
    }
    return this
      ._restApiService
      .PostMethod('ValidateLock/ValidatePeriodLock', parameters)
      .toPromise();
  }

  ValidateApproval(
    ClientCode: string,
    str: string,
    gEmpCode: string,
    gEmpName: string,
    formName: string
  ) {
    var parameters = {
      "ClientCode": ClientCode + '',
      "str": str + '',
      "gEmpCode": gEmpCode + '',
      "gEmpName": gEmpName + '',
      "formName": formName + ''
    }
    var resource = 'ValidateApproval/ValidateApproval';
    return this
      ._restApiService
      .PostMethod(resource, parameters)
      .toPromise();
  }

  ValidateUserRights(TranCode: string,
    UserId: string, type: string) {
      console.log(type);
      
    var Params = {
      "TranCode": TranCode + '',
      "Type": type ? type : "A",
      "UserId": UserId + ''
    }
    var resource = "Authentication/CheckUserRights"
    return this._restApiService.PostMethod(resource, Params).toPromise();

  }

  ValidatePostfixRule(
    ClientCode: string,
    gCompanyCode: string,
    EmpCode: string,
    leave_type: string,
    LYear: string,
    Fromdate: string,
    Todate: string,
    dtFromTime: string,
    dtToTime: string,
    day_type: string
  ) {

    var Parameters = {
      "ClientCode": ClientCode + '',
      "gCompanyCode": gCompanyCode + '',
      "EmpCode": EmpCode + '',
      "leave_type": leave_type + '',
      "LYear": LYear + '',
      "Fromdate": Fromdate + '',
      "Todate": Todate + '',
      "dtFromTime": dtFromTime + '',
      "dtToTime": dtToTime + '',
      "day_type": day_type + ''
    };

    var resource = "LeaveApplication/validatePostfixRule";
    return this
      ._restApiService
      .PostMethod(resource, Parameters)
      .toPromise();
  }
}
