import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-header-event-model',
  templateUrl: './header-event-model.component.html',
  styleUrls: ['./header-event-model.component.css']
})
export class HeaderEventModelComponent implements OnInit {

  wishForm: FormGroup;
  EventDetails: any;

  constructor(
    private _ref: DynamicDialogRef,
    private _formBuilder: FormBuilder,
    private _dialogConfig: DynamicDialogConfig
  ) { this.formInit(); }

  ngOnInit(): void {
    // console.log(this._dialogConfig.data)
    this.EventDetails = this._dialogConfig.data;
  }

  formInit() {
    this.wishForm = this._formBuilder.group({
      WishMsg: ['', [Validators.required, Validators.maxLength(100)]],
      submitted: [],
    })
  }

  Confirm() {
    //debugger;
    //this.list = data;
    this.wishForm.controls['submitted'].setValue(true);
    if (this.wishForm.valid) {
      // this.SendWishMessage();
      this._ref.close(this.wishForm);
    } else {
      this._ref.close(this.wishForm);
      this.wishForm.markAllAsTouched();
      this.wishForm.markAsDirty();
      // console.log(this.SendMsgform);
      // this.messageService.add({
      //   severity: "warn",
      //   summary: "Leave Application",
      //   detail: "Please fill the form properly.",
      // });
      //this._toastr.warning("Please fill the form properly.")
    }
  }

  resetComponent() {
    this._ref.close();
  }

  // SendWishMessage() {
  //   debugger
  //   var Params = {
  //     EmpCode: this.SendMessageData.RefEmp_Code + '',
  //     WishMsg: this.SendMsgform.controls['WishMsg'].value + '',
  //     WishByEmpCode: this.userData.Emp_Code + ''
  //   }
  //   var resource = 'EventsAndNews/SendWishMessage'
  //   this._restApiService.PostMethod(resource, Params).subscribe((result) => {
  //     if (result.statusCode == '1') {
  //       this._toastr.success(result.message, 'Success!');
  //       this.GetListOfReadNotification();
  //     } else {
  //       this._toastr.warning(result.message, 'Warning!')
  //     }
  //   })
  // }

}
