import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { LoadingService } from '../services/loading.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(
    private _configurationService: ConfigurationService,
    private _loading: LoadingService,
    private _router: Router,
    private _toastr: ToastrService,
    public _dialogService: DialogService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._loading.setLoading(true, request.url);
    if (request.url.startsWith(this._configurationService.config.ApiUrl + 'api/Authentication/GetLoginWithEmpCode')) {
      return next
        .handle(request)
        .pipe(
          catchError(
            (err) => {
              if (err.status === 401 || err.status === 403) {
                // debugger
                this._dialogService.dialogComponentRefMap.forEach(dialog => {
                  dialog.destroy();
                })
                this._loading.setLoading(false, request.url);
                this._toastr.warning("Please log in again", "Session Expired!");
                var clientURL = localStorage.getItem("clientURL");
                localStorage.clear();
                setTimeout(() => {
                  this._dialogService.dialogComponentRefMap.forEach(dialog => {
                    dialog.destroy();
                  });
                }, 2000);
                if (clientURL && clientURL != "" && clientURL != null) {
                  // window.location.replace(clientURL);
              
                  var newTab =  window.open(clientURL,'_blank');
                  if(newTab){
                    window.close();
                  }
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 3000);
                }else{
                  this._router.navigate(["/login"]);
                }
               
                // this._router.navigate(["/login"]);
              }
              setTimeout(() => {
                this._loading.setLoading(false, request.url);
                //this._toastr.error("Something went wrong!", "Error");
              }, 0)
              return throwError(err);
            }
          )
        )
        .pipe(
          map<HttpEvent<any>, any>(
            (evt: HttpEvent<any>) => {
              if (evt instanceof HttpResponse) {
                setTimeout(() => {
                  this._loading.setLoading(false, request.url);
                }, 0)
              }
              return evt;
            }
          )
        );
    }
    const isLoggedIn = localStorage.getItem('access_token') != (null && undefined && '') ? true : false;
    const isApiUrl = request.url.startsWith(this._configurationService.config.ApiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
    }
    return next
      .handle(request)
      .pipe(
        catchError(
          (err) => {
            if (err.status === 401 || err.status === 403) {
              // debugger
              this._loading.setLoading(false, request.url);
              this._toastr.warning("Please log in again", "Session Expired!");
              var clientURL = localStorage.getItem("clientURL");
              localStorage.clear();
              if (clientURL && clientURL != "" && clientURL != null) {
                // window.location.replace(clientURL);
            
                var newTab =  window.open(clientURL,'_blank');
                if(newTab){
                  window.close();
                }
                // setTimeout(() => {
                //   window.location.reload();
                // }, 3000);
              }else{
                this._router.navigate(["/login"]);
              }
              
            }
            setTimeout(() => {
              this._loading.setLoading(false, request.url);
              //this._toastr.error("Something went wrong!", "Error");
            }, 0);
            setTimeout(() => {
              this._dialogService.dialogComponentRefMap.forEach(dialog => {
                dialog.destroy();
              });
            }, 2000);

            return throwError(err);
          }
        )
      )
      .pipe(
        map<HttpEvent<any>, any>(
          (evt: HttpEvent<any>) => {
            if (evt instanceof HttpResponse) {
              setTimeout(() => {
                this._loading.setLoading(false, request.url);
              }, 0)
            }
            return evt;
          }
        )
      );
  }
}
