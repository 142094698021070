import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "./core/auth/authentication.guard";
import {NetworkErrorHandelComponent} from "../app/shared/component/network-error-handel/network-error-handel.component"
import { OfflineGuard } from "./shared/component/offline-guard/offline.guard";

const routes: Routes = [
  { path: "candidate-entry/:str", loadChildren: () => import(`./candidate-entry/candidate-entry.module`).then((m) => m.CandidateEntryModule), canActivate: [OfflineGuard] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: "login", loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule) },
  { path: "error", loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule) },
  { path: "features", loadChildren: () => import(`./features/features.module`).then((m) => m.FeaturesModule), canActivate: [AuthenticationGuard,OfflineGuard] },
  { path: '#', redirectTo: '/login' },
  {path:'table',loadChildren: () => import(`./table/table.module`).then((m) => m.TableModule), canActivate: [OfflineGuard] },
  { path: 'networkerrorpage', component: NetworkErrorHandelComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' }),],
  exports: [RouterModule],
})
export class AppRoutingModule { }
