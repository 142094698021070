import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlphaColor } from "src/app/core/enum/alpha-color";

@Component({
  selector: "app-approved-application-tab",
  templateUrl: "./approved-application-tab.component.html",
  styleUrls: ["./approved-application-tab.component.css"],
})
export class ApprovedApplicationTabComponent implements OnInit {
  @Input() applicationDetails: any = [];
  @Input() ApplicationRecord: any = [];
  @Input() approvalsListMonthYearWiseForDisplay1: any = [];
  @Input() isEditable = false;

  public userData!: any;
  public clientCode!: any;
  public responsiveOptions!: any;

  constructor(
    private _router: Router,
    private route: ActivatedRoute
  ) {
    this.initResponsiveOption();
  }

  ngOnInit() {
    console.log(this.approvalsListMonthYearWiseForDisplay1);
    this.userData = JSON.parse(localStorage.getItem("LoginData")).Login[0];
    this.clientCode = localStorage.getItem("ClientCode");
  }

  private initResponsiveOption() {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 6,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 4,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '360px',
        numVisible: 1,
        numScroll: 1
      },
    ];
  }
  viewAllApplication(application) {
    debugger;

    var route = "edit-application-list/";
    var applicationType = "";
    console.log(application);
    switch (application) {

      case "New Travel Application":
        applicationType = "Edit New Travel Application";
        route += "edit-new-travel-application";
        break;

      case "New Expense Application":
        route += 'edit-new-expense';
        applicationType = 'Edit New Expense Application'
        break;

      case "Getpass Application":
        route += 'edit-gatepass';
        applicationType = "Edit Gatepass Application"
        break;

      case "Leave Application":
        route += 'edit-leave/0';
        applicationType = "Edit Leave Application";

        break;
      case "C-Off Application":
        route += 'edit-leave/1';
        applicationType = "Edit C-Off Application";

        break;
      case "OSD Application":
        applicationType = "OSD Application";
        route += 'edit-osd';
        break;
      case "Shift Change Application":
        route += "edit-shift-change";
        applicationType = "Edit Shift Change Application";
        break;

      case "COFF Earned":
        route += "edit-coff-earned";
        applicationType = 'C-Off Earning';

        break;
      case "Visitor Request Application":
        route += "edit-visitor-request";
        applicationType = "Visitor Request";

        break;
      // case "Meeting":
      //   route += "meeting-invitations";

      //   break;
      case "Expense Application":
        applicationType = "Expense Reimbursement";
        route += "edit-expense";

        break;
      case "Epunch Application":
        applicationType = "E-Punch Application";
        route += "edit-epunch";

        break;
      case "Mispunch Application":
        applicationType = "Regularization Application";
        route += "edit-miss-punch";

        break;
      case "WFH Application":
        applicationType = "WFH Application";
        route += "edit-wfh";

        break;
      case "Loan Application":
        applicationType = "Loan Application";
        route += "edit-loan";

        break;
      case "Travel Application":
        applicationType = "Travel Application";
        route += "edit-travel";

        break;
      case "Over Time":
        applicationType = "Over Time";
        route += "edit-over-time";

        break;
      case "Exit Request":
        route += "edit-exit-request";

        break;
      case "Job Requisition":
        applicationType = "Job Requisition Application";
        route += "edit-job-requisition";

        break;
      case "Advance Request":
        applicationType = "Advance Request";
        route += "Advance-Request";

        break;
      // case "Employee Profile Update":
      //   applicationType = "Resignation Application";
      //   route += "profile-update";

      //   break;
      default:
        return;
    }

    localStorage.removeItem("applicationType");
    localStorage.setItem("applicationType", applicationType);
    this._router.navigate([route], { relativeTo: this.route });
  }

  GetApplicationClick(application) {
    debugger

    var route = "application-list/";
    var applicationType = "";
    var detailsType = "";
    console.log(application);
    switch (application.Doc_Type) {
      case "LA":
        route += "leave-Details/0";
        applicationType = "Leave Application";
        detailsType = "ApproverLeaveDetails";
        break;
      case "GP":
        route += "Gatepass-details";
        applicationType = "Getpass Application";
        detailsType = "Gatepass-details";
        break;
      case "COF":
        route += "leave-Details/1";
        applicationType = "C-Off Application";
        detailsType = "ApproverLeaveDetails";
        break;
      case "OSD":
        route += "OSD-Details";
        applicationType = "OSD Application";
        detailsType = "ApproverOSDDetails";
        break;
      case "SC":
        route += "Shift-Change-Details";
        applicationType = "Shift Change Application";
        detailsType = "ApproverShiftChangeDetails";
        break;
      case 'CERN':
        route += 'C-Off-EarningDetails';
        applicationType = 'C-Off Earning';
        detailsType = "ApproverCoffEarnedDetails";
        break;
      case "VR":
        route += "vistior-info-details";
        applicationType = "Visitor Request";
        detailsType = "ApprovalAppVDetails";
        break;
      case 'Meeting':
        route += 'meeting-invitations';
        applicationType = 'Meeting Invitations Application';
        break;
      case "EXP":
        route += "Expense-Details";
        applicationType = "Expense Reimbursement";
        detailsType = "ApproverExpenseDetails";
        break;
      case "EPNC":
        route += "E-Punch-Details";
        applicationType = "E-Punch Application";
        detailsType = "ApproverEPunchDetails";
        break;
      case "MP":
        route += "Misspunch-Details";
        applicationType = "Regularization Application";
        detailsType = "ApproverMisspunchDetails";
        break;
      case "WFH":
        route += "wfh-details";
        applicationType = "WFH Application";
        detailsType = "ApprovalWFHInfo";
        break;
      case "LOA":
        route += "Loan-Details";
        applicationType = "Loan Application";
        detailsType = "ApproverLoanDetails";
        break;
      case "TRV":
        route += "Travel-Details";
        applicationType = "Travel Application";
        detailsType = "ApprovalTRVInfo";
        break;
      case "OT":
        route += "OverTime-Details";
        applicationType = "Over Time";
        detailsType = "ApprovalOTInfo";
        break;
      case "EXR":
        route += "Exit-Request-Details";
        applicationType = "Resignation Application";
        detailsType = "ApproverExitRequestDetails";
        break;
      case 'EPU':
        route = '/features/empprofileupdate/' + application.Emp_Code + '';
        this._router.navigate([route], { state: { modeType: 'E' } });
        detailsType = "ApproverPofileDetails";
        applicationType = 'Profile Update Application';
        break;
      case "JOBR":
        route += "Job-Requisition-Details";
        applicationType = 'Job Requistion Application';
        detailsType = "ApproverJobRequistionDetails";
        break;
      case "ADV":
        applicationType = "Advance Request";
        route += "Advance-Details";
        detailsType = "ApprovalAdvInfo";
        break;
      default:

        return;
    }
    localStorage.removeItem(detailsType);
    localStorage.setItem(detailsType, JSON.stringify(application));
    localStorage.removeItem("Aapplicationname");
    localStorage.setItem("Aapplicationname", route);
    localStorage.removeItem("applicationType");
    localStorage.setItem("applicationType", applicationType);
    this._router.navigate([route], { relativeTo: this.route, state: { modeType: 'E' } });
  }

  public getBgColor(character: string) {
    return AlphaColor.colors.find(x => x.character === character.toUpperCase()).color;
  }
}
