import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import {DialogModule, Dialog} from 'primeng/dialog'
import { HeaderViewAttachmentComponent } from '../header-view-attachment/header-view-attachment.component';
@Component({
  selector: 'app-header-eventdetails',
  templateUrl: './header-eventdetails.component.html',
  styleUrls: ['./header-eventdetails.component.css']
})
export class HeaderEventdetailsComponent implements OnInit {
  
  EventDetails: any;
  ListOfAttechment: any;
  AttechmentModal: boolean;
  AttechmentUser: any;
  PdfAttechment: any;
  taggedEmployees:Number;
  ref:any
  str: String;
  constructor(
    private _ref: DynamicDialogRef,
    private _formBuilder: FormBuilder,
    private _dialogConfig: DynamicDialogConfig,
    private _restApiService: RestApiService, 
    private _dialogService: DialogService,
  ) {
    this.EventDetails = this._dialogConfig.data;
    // // this.EventDetails.Notification_Sub=this.EventDetails.Notification_Sub?this.EventDetails.Notification_Sub.replace("\n", "<br>"):'';
    console.log("EVEEEEEEEE",this.EventDetails);
    if(this.EventDetails.Notification_DocType=='Announcement'){
      this.GetEmpSelectedInAlert();
    }else{
      this.GetEmpListForEvent();
    }
    // console.log(this.EventDetails.Notification_Sub);
    
  }

  ngOnInit(): void {
  }
  GetEmpListForEvent(){
    let resource="EventsAndNews/GetEmpListForEvent";
    let params={
      "sMode":"A",
      "EventId":this.EventDetails.DocNo+""
    }
    this._restApiService.PostMethod(resource,params).subscribe(res=>{
      console.log(res);
      let data=JSON.parse(res.data);
      console.log("DATAAA",data);
      this.taggedEmployees=data.length;
    })
  }
  GetEmpSelectedInAlert(){
    let resource="HRAnnouncements/GetEmpSelectedInAlert";
    let params={
      "DocNo":this.EventDetails.DocNo+"",
      "RetriveType":this.EventDetails.AnnSentTo+""
    }
    this._restApiService.PostMethod(resource,params).subscribe(res=>{
      console.log(res);
      let data=JSON.parse(res.data);
      console.log(data);
      this.taggedEmployees=data.length;
    })
  }
  GetAlertAttachment() {
    //debugger
    this.ref = this._dialogService.open(HeaderViewAttachmentComponent, {
      header:  'Attachment',
      width: "auto",
      contentStyle: { "height": "900px", "overflow": "auto" },
      baseZIndex: 10000,
      data: this.EventDetails
    });
    this.ref.onClose.subscribe((msg: any) => {
      // if (data.Read_Ind == 0) {
      //   this.singleReadNotification(data);
      // }
    });

    // this.AttechmentModal = isTrue;
    // var resource = "HRAnnouncements/GetAlertAttachment";
    // var Params = {
    //   DocNo:this.EventDetails.DocNo + ""
    // }

    // this._restApiService.PostMethod(resource, Params).subscribe((result) => {
    //   if (result.statusCode == '1') {
    //     var Data = JSON.parse(result.data);
    //     console.log(Data);
        
    //     this.ListOfAttechment = Data[0];
    //     if (this.ListOfAttechment.Attachment_Ext != '.pdf') {
    //       let ext=this.ListOfAttechment.Attachment_Ext.substring(1);
    //       console.log(ext);
          
    //       var newdata = this.ListOfAttechment.Attachment_Data.replace(`data:image/${ext};base64,`, '').replace('data:image/png;base64,', '')
  
    //       this.AttechmentUser = newdata;
    //       console.log("Attachment",this.AttechmentUser);
          
    //     } else {
    //       var DataPdf = this.ListOfAttechment.Attachment_Data.replace('data:application/pdf;base64,', '')
    //       this.PdfAttechment = DataPdf;
         
    //     }

    //   }
    // })
  }
}
