import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  baseUrl='';

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpClient,
  ) {
    this.baseUrl = configurationService.config.ApiUrl;
   }

  public GetMethod (resource: string, parameters: string): Observable<any>{
    return this
            .http
            .get(`${this.baseUrl}${resource}${parameters}`);
  }

  public GetMethod2 (resource: string, parameters: string): Observable<any[]>{
    return this
            .http
            .get<any[]>(`${this.baseUrl}${resource}${parameters}`)
            .pipe(
              map(
                (response:any )=> (response.value)
              )
            );
  }
  public GetMethodContentTypeJson (resource: string, parameters: any): Observable<any>{
    var headers = new HttpHeaders().set('Content-Type', 'application/json');
    var httpParam = new HttpParams().set('requestData', JSON.stringify(parameters));

    return this
            .http
            .get(`${this.baseUrl}${resource}`, {headers: headers, params:httpParam});
  }
  // public GetMethodMultipleParam(resource: string, parameters: any = null): Observable<any>{

  //   return this
  //           .http
  //           .get(`${this.baseUrl}${resource}`, parameters);
  // }
  public GetMethodQueryable(resource: string, parameters: string): Observable<any>{
    return this
            .http
            .get(`${this.baseUrl}${resource}${parameters}`);
  }

  public PostMethod(resource: string, parameters: any = null): Observable<any>{
    return this
            .http
            .post(`${this.baseUrl}${resource}`, parameters);
  }

  public PostMethodWithTextResponse(resource: string, parameters: any = null): Observable<any>{
    return this
            .http
            .post(`${this.baseUrl}${resource}`, parameters, {responseType: 'text'});
  }

  public PutMethod(resource: string, parameters: any): Observable<any>{
    return this
            .http
            .put(`${this.baseUrl}${resource}`, parameters);
  }

  public DeleteMethod(resource: string, parameters: any): Observable<any>{
    return this
            .http
            .delete(`${this.baseUrl}${resource}${parameters}`);
  }
}
