import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

export interface Config {
  ApiUrl: string;
  ResourceUri: string;
  ResourceScope: string;
  ClientId: string;
  Authority: string;
  SignUpAuthority: string;
  ResetPasswordAuthority: string;
  EditProfileAuthority: string;
  ChangePasswordAuthority: string;
  CacheLocation: string;
  RedirectUri: string;
  RedirectUriForAquireToken: string;
  RedirectUriForSignUp: string;
  RedirectUriForForgotPassword: string;
  PostLogoutRedirectUri: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService implements OnDestroy {
  isOnline: boolean = true;
  wasOnline: boolean = true;
  public config: Config;
  private http: HttpClient;
  private debounceTimeout: any;
  constructor(private readonly httpHandler: HttpBackend, private router: Router, private location: Location) {
    debugger
    this.http = new HttpClient(httpHandler);
    window.addEventListener('online', this.onOnline.bind(this));
    window.addEventListener('offline', this.onOffline.bind(this));
    this.checkOnlineStatus();
  }

  ngOnDestroy(): void {
    window.removeEventListener('online', this.onOnline.bind(this));
    window.removeEventListener('offline', this.onOffline.bind(this));
  }

  checkOnlineStatus() {
    debugger
    this.isOnline = navigator.onLine;
    if (!this.isOnline) {
      this.onOffline();
    } else {
      this.onOnline
    }
  }

  onOnline() {
    debugger
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      debugger
      this.isOnline = true;
      if (!this.wasOnline) {
        this.wasOnline = true;
        console.log("Online");
        this.location.back();
        this.location.back();
      }
    }, 300)
  }

  onOffline() {
    debugger
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      debugger
      this.isOnline = false;
      this.wasOnline = false;
      console.log("Offline");
      this.router.navigate(['/networkerrorpage'])
    }, 300)
  }

  loadConfig() {
    return this.http
      .get<Config>('./../../../assets/config.json')
      .toPromise()
      .then(config => {
        this.config = config;
        // console.log('config is ', this.config);
      });
  }
}
